<div class="wave">
    <img src="svg/wave.svg" alt="footer wave"/>
</div>

<style>
  .wave {
    width: 100%;
    position: fixed;
    opacity: 5%;
    bottom: 0;
  }
</style>
